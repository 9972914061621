import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="footer-section footer-style2 z-1 position-relative blackbg">
  <div className="container">
    <div className="footer-social-logo d-flex align-items-center justify-content-sm-between justify-content-center">
      <a href="/">
        <img src="/Group 1597883311.png" style={{height:'100px',width:'100px'}} alt="logo-img" />
      </a>
      <div className="social-wrapper d-flex align-items-center">
        <a href="https://www.facebook.com/profile.php?id=61567770061213" target='_blank' className=" black">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="https://www.linkedin.com/company/wyse-global-health/about/?viewAsMember=true" target='_blank' className=" black">
          <i className="fa-brands fa-linkedin-in" />
        </a>
        <a href="https://www.instagram.com/wysehealth/profilecard/?igsh=MXhsb2NqYzVwcHN0Ng==" target='_blank' className=" black">
          <i className="fab fa-instagram" />
        </a>
        <a href="https://x.com/health_wys85810" target='_blank' className=" black">
          <i className="fa-brands fa-x" />
        </a>
      </div>
    </div>
    <div className="footer-widgets-wrapper">
      <div className="row g-4 justify-content-between">
        <div className="col-lg-3 col-md-6 col-sm-5 d-flex justify-content-lg-center">
          <div
            className="single-footer-widget wow fadeInUp"
            data-wow-delay="0.7s"
          >
            <div className="widget-head">
              <h4 className="white ">Contact</h4>
            </div>
            <ul className="footer-info d-flex flex-column gpa-xxl-4 gap-3">
              <li className="d-flex align-items-center gap-xl-3 gap-2">
                <span className="icon d-center">
                  <i className="p1-clr fa-solid fa-location-dot" />
                </span>
                <div className="cont">
                  <span className="white fs-seven d-block ">Address</span>
                  <a
                    href="javascript:void(0)"
                    className="fs-six fw_500 white sub-font "
                  >
                  #43, Wework Galaxy, Residency Road, Bangalore 560025
                  </a>
                </div>
              </li>
              <li className="d-flex align-items-center gap-xl-3 gap-2">
                <span className="icon d-center">
                  <i className="p1-clr fa-solid fa-phone" />
                </span>
                <div className="cont">
                  <span className="white fs-seven d-block ">Phone Number</span>
                  <a
                    href="javascript:void(0)"
                    className="fs-six fw_500 white sub-font "
                  >
                  7899802970 
                  </a>
                </div>
              </li>
              <li className="d-flex align-items-center gap-xl-3 gap-2">
                <span className="icon d-center">
                  <i className="p1-clr fa-solid fa-envelope" />
                </span>
                <div className="cont">
                  <span className="white fs-seven d-block ">Email</span>
                  <a
                    href="javascript:void(0)"
                    className="fs-six fw_500 white sub-font "
                  >
                  info@wyse.health 
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-5 d-flex justify-content-lg-center">
          <div
            className="single-footer-widget wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="widget-head">
              <h4 className="white fw_600 ">Pages</h4>
            </div>
            <ul className="list-area ">
              <li>
                <a className='' href="/"><i className="fa-solid fa-angles-right " />&nbsp;About Us</a>
              </li>
              <li>
                <a className='' href="/"><i className="fa-solid fa-angles-right " />&nbsp;Services</a>
              </li>
              <li>
                <a className='' href="/"><i className="fa-solid fa-angles-right " />&nbsp;Why Chose Us</a>
              </li>
              <li>
                <a className=''href="index2.html#"><i className="fa-solid fa-angles-right " />&nbsp;DNA Doctors</a>
              </li>
              <li>
                <a className='' href="index2.html#"><i className="fa-solid fa-angles-right " />&nbsp;Blog And News</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-lg-center">
          <div
            className="single-footer-widget wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="widget-head">
              <h4 className="white fw_600 ">Services</h4>
            </div>
            <ul className="list-area">
              <li>
                <a
                  href="javascript:void(0)"
                  className="d-flex align-items-center gap-2 "
                >
                  <i className="fa-solid fa-angles-right " /> Corporate Health
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="d-flex align-items-center gap-2 "
                >
                  <i className="fa-solid fa-angles-right " /> Chronic Disease
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="d-flex align-items-center gap-2 "
                >
                  <i className="fa-solid fa-angles-right " /> Longevity
                </a>
              </li>
              <li>
              <a
                href="javascript:void(0)"
                className="d-flex align-items-center gap-2 "
              >
                <i className="fa-solid fa-angles-right " /> Sports
              </a>
            </li>
            <li>
            <a
              href="javascript:void(0)"
              className="d-flex align-items-center gap-2 "
            >
              <i className="fa-solid fa-angles-right " /> Beauty
            </a>
          </li>
              {/* <li>
                <a
                  href="javascript:void(0)"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="fa-solid fa-angles-right" /> SwiftCare Urgent
                  Center
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="fa-solid fa-angles-right" /> WellSpring Women's
                  Clinic
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-7">
          <div
            className="single-footer-widget wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="widget-head">
              <h4 className="white fw_600 ">Newsletter</h4>
            </div>
            <div className="footer-content">
              <p className="white">
                Medical services are an essential part of our lives, offering
                care and treatment for various
              </p>
            </div>
            <form action="index2.html#" className="form-cmn-style1">
              <input type="text" placeholder="Enter your email" />
              <button
                type="button"
                className="common-btn text-nowrap box-style first-box d-inline-flex justify-content-center align-items-center fs-seven fw_600 gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded-5"
              >
                Subscribe
                <svg
                  width={21}
                  height={16}
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.7074 8.79688H0.291016V7.04688H20.7074V8.79688Z"
                    fill="#090A0B"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8338 7.04688C15.7184 7.04688 12.3555 10.666 12.3555 14.5252V15.4002H14.1055V14.5252C14.1055 11.5951 16.7218 8.79688 19.8338 8.79688H20.7083V7.04688H19.8338Z"
                    fill="#090A0B"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8338 8.79675C15.7184 8.79675 12.3555 5.17759 12.3555 1.31836V0.443359H14.1055V1.31836C14.1055 4.24854 16.7218 7.04675 19.8338 7.04675H20.7083V8.79675H19.8338Z"
                    fill="#090A0B"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="footer-bottom-wrap">
        <p className="body-font fs-seven">
          © 2024 Wyse Health | All Rights Reserved
        </p>
        <ul className="privacy">
          <li>
            <a href="javascript:void(0)" className="fs-seven">
              Terms &amp; Condition
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="fs-seven">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="fs-seven">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
