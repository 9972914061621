import React,{useState} from 'react'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
function Home() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const { name, email, phone, subject, message } = formData;

    if (!name || !email || !phone || !subject || !message) {
      toast.error('All fields are required!');
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    if (subject.length < 3) {
      toast.error('Subject must be at least 3 characters long.');
      return;
    }
  
    if (message.length < 10) {
      toast.error('Message must be at least 10 characters long.');
      return;
    }
    toast.loading('please wait while sending Message',{
      duration:400
    })
    try {
      const response = await axios.post('https://wyse.health/api/form', formData);
      console.log(response);
      toast.success('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form. Please try again.');
    }
  };

  return (
    <div className="App">
     <Header/>
     <>
     <Toaster position='top-center' /> 
     <section className="banner-section2 white-bg fix">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-xxl-5 col-xl-5 col-lg-5">
          <div className="hero-content-version2">
           
            <h1 className="black wow fadeInUp mb-40" data-wow-delay="0.6s">
            Discover the  <br />
              <span className="position-relative z-1 w-100">
              Power Within Your DNA.
                <img
                  src="assets/img/element/title-badge1.png"
                  alt="img"
                  className="title-badge1 d-md-block d-none w-100"
                />
              </span>
              {/* <span className="fw-normal">Personalized Health Insights</span> */}
            </h1>
            <div className="d-flex align-items-center gap-xl-2 gap-1">
              
              <span className="fs-six pra " style={{cursor: "pointer"}}>Learn More</span>
            </div>
            <div className="hero2-counter-wrap">
              <div className="hero-count-item ">
                <h2 className="black">2.2 Mil+</h2>
                <p className="black">
                  Genetic  Tests accomplished 
                </p>
              </div>
              <div className="hero-count-item ">
                <h2 className="black">80 Mil+</h2>
                <p className="black">
                Polymorphisms & Mutations Analyzed
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xl-2 col-lg-3 d-lg-block d-none">
          <div className="hero2-thumb">
            <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_5e677072-b4d2-427f-904e-0c5453cacc45 (3).jpg" alt="DNA Image" />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-4 col-lg-4">
          <form onSubmit={handleSubmit}  className="hero-appoinment">
            <h4 className="black mb-xxl-3 mb-2">Let’s Connect</h4>
            <p className="pra mb-xxl-3 mb-2">
              Unlock the power of your DNA for a healthier, informed life.
            </p>
            <input type="text" placeholder="Your Name" name='name'  value={formData.name}
                  onChange={handleChange} className="mb-3" />
            <input type="text" placeholder="Your Phone" name='phone'  value={formData.phone}
                  onChange={handleChange} className="mb-3" />
            <input type="email" placeholder="Your Email" name='email'  value={formData.email}
                  onChange={handleChange} className="mb-3" />
            <input type="text" placeholder="Your Subject" name='subject'  value={formData.subject}
                  onChange={handleChange} className="mb-3" />
            <input type="text" placeholder="Your Message" name='message'  value={formData.message}
                  onChange={handleChange} className="mb-3" />

            {/* <select name="selected" className="mb-3">
              <option value={1}>Select Service</option>
              <option value={1}>Health Risk Analysis</option>
              <option value={1}>Ancestry Test</option>
              <option value={1}>Personalized Diet Plan</option>
            </select> */}
            <button
              type='submit'
              className="common-btn box-style p2-bg w-100 text-nowrap d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs-seven fw-medium white overflow-hidden rounded100 wow fadeInRight"
              data-wow-delay="0.8s"
            >
            Get in Touch
              <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>

  {/* Servie Section Start */}
  <section className="services-section cmn-bg fix section-padding">
    <div className="container">
      <div className="section-title text-center mb-60">
        <h2
          className="wow fadeInUp black visible-slowly-right"
          data-wow-delay=".3s"
        >
        Why Choose <br />
          <span className="position-relative z-1 mb-40">
          WYSE HEALTH?
            <img
              src="assets/img/element/title-badge1.png"
              alt="img"
              className="title-badge1 d-md-block d-none w-100 "
            />
          </span>
        </h2>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6">
          <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
            <div className="icon-area d-flex align-items-center gap-2">
              <div className="icon d-center">
                <img src="assets/img/icon/ser1.png" alt="icon" />
              </div>
              <h4 className="black">
                <a href="#">GenAI Integration</a>
              </h4>
            </div>
            <p className="pra">
            Personalized recommendations powered by intelligent, adaptive AI. 
            </p>
            <div className="thumb overflow-hidden reveal-left position-relative">
              <img
                src="https://photos.peopleimages.com/picture/202302/2630497-science-petri-dish-and-man-scientist-for-healthcare-research-test-analysis-or-neurology-study-in-laboratory.-focus-medical-worker-chemistry-professional-or-expert-with-blood-dna-sample-for-cancer-fit_400_400.jpg"
                alt="img"
                className="rounded-4 overflow-hidden w-100"
              />
            </div>
            <div className="text-center service-btn-inner">
              <a
                href="#"
                className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
              >
                Learn More
                <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
            <div className="icon-area d-flex align-items-center gap-2">
              <div className="icon d-center">
                <img src="assets/img/icon/ser2.png" alt="icon" />
              </div>
              <h4 className="black">
                <a href="#">Blockchain Security</a>
              </h4>
            </div>
            <p className="pra">
            Your genetic data, protected by top-tier privacy technology. 
            </p>
            <div className="thumb overflow-hidden reveal-left position-relative">
              <img
                src="https://media.licdn.com/dms/image/D5612AQF33wtEWs78eA/article-cover_image-shrink_720_1280/0/1696180341787?e=2147483647&v=beta&t=_ANEdEVIL1J9DxaBPW4m-YAZFEqbTg3R8G9l98-iMYI"
                alt="img"
                className="rounded-4 overflow-hidden w-100"
              />
            </div>
            <div className="text-center service-btn-inner">
              <a
                href="#"
                className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
              >
                Learn More
                <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
            <div className="icon-area d-flex align-items-center gap-2">
              <div className="icon d-center">
                <img src="assets/img/icon/ser3.png" alt="icon" />
              </div>
              <h4 className="black">
                <a href="#">Precision Insights</a>
              </h4>
            </div>
            <p className="pra">
            Health strategies crafted exclusively from your genetic profile for actionable, effective results.
            </p>
            <div className="thumb overflow-hidden reveal-left position-relative">
              <img
                src="https://media.istockphoto.com/id/1012405368/photo/scientists-in-laboratory-working-on-research.jpg?s=612x612&w=0&k=20&c=qGuh-kPQ2EBLU1dHZ2aMv5CDAt4pv6uTr-oRdOZO2Ck="
                alt="img"
                className="rounded-4 overflow-hidden w-100"
              />
            </div>
            <div className="text-center service-btn-inner">
              <a
                href="#"
                className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
              >
                Learn More
                <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* About Section Start */}
  <section className="about-section2 space-bottom cmn-bg fix">
  <div className="container">
    <div className="row g-4 align-items-center justify-content-between flex-row-reverse">
      <div className="col-lg-6">
        <div className="about-content2">
          <div className="section-title mb-4">
            <span className="cmn-tag p1-bg heading-font">About WYSE HEALTH </span>
            <h2
              className="wow fadeInUp black visible-slowly-right mb-xxl-4 mb-3"
              data-wow-delay=".3s"
            >
            Your Health, Perfected  <br /> 
              <span className="position-relative z-1">
              Through Precision
                <img
                  src="assets/img/element/title-badge1.png"
                  alt="img"
                  className="title-badge1 d-md-block d-none w-100"
                />
              </span>
            
            </h2>
            <p className="pra mb-sm-4 mb-3 position-relative syle-pra d-flex align-items-center gap-3">
              <img
                src="assets/img/element/pra-element.png"
                alt="element"
                className="d-sm-block d-none"
              />
              We go beyond traditional wellness by offering precision health insights rooted in your DNA.
               Through advanced genomics, GenAI intelligence, and blockchain-secured privacy, we provide a
                truly personalized approach to wellness. 
            </p>
            <p className="pra">
            Each recommendation is tailored from your unique genetic code, empowering you to make informed choices
             for a healthier, brighter future. Our vision is simple: to set a new standard in DNA-backed wellness,
              unlocking the full potential of your health
            </p>
          </div>
          <div className="about-point mb-40">
            <div className="about-point-item d-flex align-items-center gap-sm-3 gap-2">
              <img src="assets/img/icon/about-icon1.png" alt="icon" />
              <h4 className="black">
                Genetic Analysis <br /> Precision Insights
              </h4>
            </div>
            <div className="about-point-item d-flex align-items-center gap-sm-3 gap-2">
              <img src="assets/img/icon/about-icon2.png" alt="icon" />
              <h4 className="black">
                DNA Mapping <br /> Future Health Solutions
              </h4>
            </div>
          </div>
          <a
            href="#"
            className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
          >
            Learn More
            <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
          </a>
        </div>
      </div>
      <div className="col-xl-5 col-lg-6">
        <div className="about-thumb2 reveal-left rounded-4">
          <img
            src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_13ce132d-6088-4158-a4dc-02039cf202a7.png"
            alt="img"
            className="w-100 rounded-4"
          />
        </div>
      </div>
    </div>
  </div>
</section>

  {/* Feature Section Start */}
 

  <section className="feature-section fix section-padding">
  <div className="container">
    <div className="section-title text-center mb-60">
      <span className="cmn-tag p1-bg heading-font">Our Services</span>
      <h2 className="wow fadeInUp black visible-slowly-right" data-wow-delay=".3s">
        Comprehensive DNA Testing <br />
        & Genetic Analysis
        <span className="position-relative z-1">
          Solutions
          <img src="assets/img/element/title-badge1.png" alt="img" className="title-badge1 d-md-block d-none w-100" />
        </span>
      </h2>
    </div>
    <div className="featue-wrapper feaure-wrapper2 position-relative white-bg">
      <div className="feature-inner">
        <div className="ins d-flex flex-column gap-xxl-4 gap-3">
          <div className="line" />
          <div className="feature-items">
            <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
              <h4 className="black fw_600 text-nowrap">
                <a href="#" className="black fw_600 text-nowrap">
                Corporate Health
                </a>
              </h4>
              <ul className="feature-list d-flex flex-column gap-1">
                <li className="text-nowrap">Discover your genetic roots</li>
              </ul>
              <p className="pra fs-seven">
                Trace your ancestry back thousands of years, exploring your family’s migration patterns and unique genetic heritage.
              </p>
            </div>
            <a href="#" className="cmn-arrows d-center">
              <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
            </a>
            <img src="assets/img/choose/feature4.jpg" alt="img" className="extra-feature" />
          </div>
          <div className="line" />
        </div>
        <div className="ins d-flex flex-column gap-xxl-4 gap-3">
          <div className="line" />
          <div className="feature-items">
            <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
              <h4 className="black fw_600 text-nowrap">
                <a href="#" className="black fw_600 text-nowrap">
                Chronic Disease
                </a>
              </h4>
              <ul className="feature-list d-flex flex-column gap-1">
                <li className="text-nowrap">Predict health risks</li>
              </ul>
              <p className="pra fs-seven">
                Assess your genetic predisposition to various health conditions, empowering you to take proactive steps toward wellness.
              </p>
            </div>
            <a href="#" className="cmn-arrows d-center">
              <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
            </a>
            <img src="assets/img/choose/feature1.jpg" alt="img" className="extra-feature" />
          </div>
          <div className="line" />
        </div>
        <div className="ins d-flex flex-column gap-xxl-4 gap-3">
          <div className="line" />
          <div className="feature-items">
            <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
              <h4 className="black fw_600 text-nowrap">
                <a href="#" className="black fw_600 text-nowrap">
                Longevity
                </a>
              </h4>
              <ul className="feature-list d-flex flex-column gap-1">
                <li className="text-nowrap">Personalized nutrition</li>
              </ul>
              <p className="pra fs-seven">
                Learn how your genes affect your nutrition needs, enabling a personalized approach to your diet for optimal health.
              </p>
            </div>
            <a href="#" className="cmn-arrows d-center">
              <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
            </a>
            <img src="assets/img/choose/feature2.jpg" alt="img" className="extra-feature" />
          </div>
          <div className="line" />
        </div>
        <div className="ins d-flex flex-column gap-xxl-4 gap-3">
          <div className="line" />
          <div className="feature-items">
            <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
              <h4 className="black fw_600 text-nowrap">
                <a href="#" className="black fw_600 text-nowrap">
                Sports
                </a>
              </h4>
              <ul className="feature-list d-flex flex-column gap-1">
                <li className="text-nowrap">Personalized medicine</li>
              </ul>
              <p className="pra fs-seven">
                Understand how your genes influence your response to medications, allowing for safer and more effective treatments.
              </p>
            </div>
            <a href="#" className="cmn-arrows d-center">
              <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
            </a>
            <img src="assets/img/choose/feature3.jpg" alt="img" className="extra-feature" />
          </div>
          <div className="line" />
        </div>
        <div className="ins d-flex flex-column gap-xxl-4 gap-3">
          <div className="line" />
          <div className="feature-items">
            <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
              <h4 className="black fw_600 text-nowrap">
                <a href="#" className="black fw_600 text-nowrap">
                Beauty
                </a>
              </h4>
              <ul className="feature-list d-flex flex-column gap-1">
                <li className="text-nowrap">Personalized medicine</li>
              </ul>
              <p className="pra fs-seven">
                Understand how your genes influence your response to medications, allowing for safer and more effective treatments.
              </p>
            </div>
            <a href="#" className="cmn-arrows d-center">
              <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
            </a>
            <img src="assets/img/choose/feature3.jpg" alt="img" className="extra-feature" />
          </div>
          <div className="line" />
        </div>
      </div>
    </div>
  </div>
</section>

  {/* Latest Project Section Start */}
  <section className="latest-projects fix">
    <div className="container">
      <div className="d-flex flex-sm-nowrap flex-wrap gap-2 align-items-end justify-content-between mb-60">
        <div className="section-title">
          <span className="cmn-tag p1-bg heading-font mb-3">
          Research & Innovation
          </span>
          <h2
            className="wow fadeInUp black visible-slowly-right"
            data-wow-delay=".3s"
          >
          Driving Genomic Health 
             <br /> for the Future.
            <span className="position-relative z-1">
              <img
                src="assets/img/element/title-badge1.png"
                alt="img"
                className="title-badge1 d-md-block d-none w-100"
              />
            </span> 
          </h2>
        </div>
        <div
          className="array-button d-flex align-items-center gap-3 wow fadeInUp"
          data-wow-delay=".5s"
        >
          <button className="array-prev d-center">
            <img src="assets/img/icon/arrow-left-black.png" alt="icon" />
          </button>
          <button className="array-next d-center active">
            <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
          </button>
        </div>
      </div>
      <div className="swiper lastes-project__wrapper">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="latest-project-slide">
              <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_df3ec3d2-2dad-4a8b-8e07-f4752b2cea00.png" alt="img" />
              <div className="ls-content text-center">
                <span className="white d-block mb-2">Genomics </span>
                <h4 className="white">
                  <a href="#" className="white">
                  exploring how to blend genetic insights with  AI to create  more tailored health solutions.  
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="latest-project-slide">
              <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_dimension_og_928bf610-5c69-40b4-b4cc-87cb2ca54114.png" alt="img" />
              <div className="ls-content text-center">
                <span className="white d-block mb-2">GenAI technology</span>
                <h4 className="white">
                  <a href="#" className="white">
                  GenAI allows us to continually refine how we interpret DNA data.
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="latest-project-slide">
              <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_dimension_og_0fc2e572-6ce7-4017-afe2-e4837ecebe60.png" alt="img" />
              <div className="ls-content text-center">
               
                <h4 className="white">
                  <a href="#" className="white">
                  Discover how WYSE GEN is shaping the future of precision health. 
                  </a>
                  <span className="white d-block mb-2 mt-2"><a>Explore More</a></span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Helth Compassionate */}
  <section className="helth-compassionate cmn-bg section-padding">
    <div className="container">
      <div className="row g-4">
        <div className="col-lg-7 h-100">
          <div className="compassionate-left-content">
            <div className="box">
              <h4 className="black mb-md-3 mb-2">
              Personalized Genetic Insights for Your Future
                            </h4>
              <p className="black mb-40 pb-2">
              Genetic testing is a critical tool in ensuring well-being, offering a comprehensive range of services that empower you to take preventive measures based on your unique DNA...
              </p>
              <a
                href="#"
                className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden rounded100"
              >
                Book An Appiontment
                <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
              </a>
            </div>
            <div className="thumb d-md-block d-none">
              <img width="400px" height="400px" src="https://cdn.pixabay.com/photo/2024/02/07/15/09/ai-generated-8559288_1280.png" alt="img" />
            </div>
          </div>
        </div>
        <div className="col-lg-5 h-100">
          <div className="compassionate-right">
            <div className="compassionate-item">
              <div className="icon d-center rounded-circle">
                <img src="assets/img/icon/compasi1.png" alt="icon" />
              </div>
              <div className="cont">
                <h5 className="white mb-2">Unlocking Your Genetic Blueprint</h5>
                <p className="white">
                DNA testing provides essential insights into your health, empowering you to make informed decisions based on your unique genetic makeup...
                </p>
              </div>
            </div>
            <div className="compassionate-item">
              <div className="icon d-center rounded-circle">
                <img src="assets/img/icon/compasi2.png" alt="icon" />
              </div>
              <div className="cont">
                <h5 className="white mb-2">Genetic Wellness Tailored for You</h5>
                <p className="white">
                Understanding your DNA is key to achieving optimal wellness. Our services focus on genetic testing and analysis...
                </p>
              </div>
            </div>
            <div className="compassionate-item">
              <div className="icon d-center rounded-circle">
                <img src="assets/img/icon/compasi3.png" alt="icon" />
              </div>
              <div className="cont">
                <h5 className="white mb-2">Innovating Health Through Genetic Data</h5>
                <p className="white">
                  Medical care encompasses a range of services aimed at the a
                  promoting health, preventing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Pricing Section Start */}
  <section className="pricing-section fix n900-bg space-top">
  <div className="container">
    <div className="section-title text-center mb-60">
      <span className="cmn-tag p1-bg heading-font">Pricing Plans</span>
      <h2 className="wow fadeInUp white visible-slowly-right" data-wow-delay=".3s">
        Where genetics meets insight <br /> partner in
        <span className="position-relative z-1">
          wellness
          <img src="assets/img/element/title-badge1.png" alt="img" className="title-badge1 d-md-block d-none w-100" />
        </span>
      </h2>
    </div>
    <div className="row g-xxl-5 g-4 justify-content-center align-items-center">
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
        <div className="pricing-items">
          <div className="pricing-head">
            <h4 className="white mb-2">Corporate Health</h4>
          </div>
          <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Discover your ethnic origins
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Connect with distant relatives
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Access family tree insights
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Explore genetic traits
            </li>
          </ul>
          <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
            Book An Appointment
            <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
        <div className="pricing-items premium">
          <div className="pricing-head">
            <h4 className="white mb-2">Chronic Disease</h4>
          </div>
          <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Assess your risk for certain diseases
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Personalized lifestyle recommendations
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Preventive health strategies
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Regular monitoring for proactive health
            </li>
          </ul>
          <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
            Book An Appointment
            <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
        <div className="pricing-items">
          <div className="pricing-head">
            <h4 className="white mb-2">Longevity</h4>
          </div>
          <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Guidance for family planning
            </li>
            <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
              <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
            </li>
          </ul>
          <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
            Book An Appointment
            <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
      <div className="pricing-items">
        <div className="pricing-head">
          <h4 className="white mb-2">Sports</h4>
        </div>
        <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
          <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
            <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
          </li>
          <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
            <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
          </li>
          <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
            <i className="fa-solid fa-angles-right white" /> Guidance for family planning
          </li>
          <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
            <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
          </li>
        </ul>
        <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
          Book An Appointment
          <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
        </a>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
    <div className="pricing-items">
      <div className="pricing-head">
        <h4 className="white mb-2">Beauty </h4>
      </div>
      <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
        <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
          <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
        </li>
        <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
          <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
        </li>
        <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
          <i className="fa-solid fa-angles-right white" /> Guidance for family planning
        </li>
        <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
          <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
        </li>
      </ul>
      <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
        Book An Appointment
        <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
      </a>
    </div>
  </div>
    </div>
  </div>
</section>


  {/* Video Bg Section Start */}
  <div className="video-section section-padding cmn-bg fix">
    <div className="container">
      <div className="video-uniquewrap position-relative d-center w-100">
        <img
          src="https://images.inc.com/uploaded_files/image/1920x1080/getty_858544742_373138.jpg"
          alt="img"
          className="w-100 rounded-4"
        />
        <a
          href="https://youtube.com/@wysehealth?si=Mwp7aFYsgdrOUaPb"
          target='_blank'
          className="video-choose position-absolute d-center rounded-circle p1-bg "
        >
          <i className="fa-solid fa-play white" />
        </a>
      </div>
    </div>
  </div>
  {/* Testimonial Section Start */}
  
  {/*<< Blog News Start >>*/}
  <section className="testimonial-sectio2 cmn-bg fix">
  <div className="container">
    <div className="row g-4 justify-content-between">
      <div className="col-xxl-4 col-xl-5 col-lg-5">
        <div className="section-title">
          <span className="cmn-tag p1-bg heading-font mb-3">
          Customer Testimonials 
          </span>
          <h2
            className="wow fadeInUp black visible-slowly-right mb-xxl-4 mb-3"
            data-wow-delay=".3s"
          >
          Real Stories of 
            <span className="position-relative z-1">
            Health Transformation
              <img
                src="assets/img/element/title-badge1.png"
                alt="img"
                className="title-badge1 d-md-block d-none w-100"
              />
            </span>
          </h2>
          <p className="pra mb-40">
            Genetic testing is revolutionizing healthcare, providing insights
            that empower individuals to make informed health decisions.
          </p>
          <div
            className="array-button d-flex align-items-center gap-3 wow fadeInUp"
            data-wow-delay=".5s"
          >
            <button className="array-prev">
              <i className="fal fa-arrow-left" />
            </button>
            <button className="array-next">
              <i className="fal fa-arrow-right" />
            </button>
          </div>
        </div>
      </div>
      <div className="col-xxl-7 col-xl-7 col-lg-7">
        <div className="swiper testimonial-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testimonial-items style2">
                <div className="ratting mb-3">
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                </div>
                <p className="fs-five pra mt-xxl-4 mt-4 mb-4">
                  "WYSE HEALTH’s Corporate Health insights have transformed my energy and focus at work. Personalized sleep and mental health strategies have made me more resilient and productive."
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-xxl-4 gap-xl-3 gap-2">
                    <img
                      src="assets/img/testimonial/testimonial-john.png"
                      alt="img"
                      className="rounded-circle"
                    />
                    <div className="cont">
                      <h4 className="black"> John M.</h4>
                      <span className="fs-seven pra">Corporate Executive </span>
                    </div>
                  </div>
                  <img
                    src="assets/img/testimonial/quote-icons.png"
                    alt="img"
                    className="quote"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-items style2">
                <div className="ratting mb-3">
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                </div>
                <p className="fs-five pra mt-xxl-4 mt-4 mb-4">
                  "The Sports Performance panel helped me optimize training and recovery based on my genetics. I’m setting personal bests and feeling stronger than ever!"
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-xxl-4 gap-xl-3 gap-2">
                    <img
                      src="assets/img/testimonial/testimonial-john.png"
                      alt="img"
                      className="rounded-circle"
                    />
                    <div className="cont">
                      <h4 className="black">Sarah T</h4>
                      <span className="fs-seven pra">Marathon Runner</span>
                    </div>
                  </div>
                  <img
                    src="assets/img/testimonial/quote-icons.png"
                    alt="img"
                    className="quote"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-items style2">
                <div className="ratting mb-3">
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                  <i className="fa-solid fa-star p3-clr fs-six" />
                </div>
                <p className="fs-five pra mt-xxl-4 mt-4 mb-4">
                  "The Longevity & Wellness program provided tailored guidance that’s improved my diet and energy. I feel empowered to age gracefully with a plan crafted just for me."
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-xxl-4 gap-xl-3 gap-2">
                    <img
                      src="assets/img/testimonial/testimonial-john.png"
                      alt="img"
                      className="rounded-circle"
                    />
                    <div className="cont">
                      <h4 className="black">Emily R.</h4>
                      <span className="fs-seven pra">Health Enthusiast </span>
                    </div>
                  </div>
                  <img
                    src="assets/img/testimonial/quote-icons.png"
                    alt="img"
                    className="quote"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
            <div className="testimonial-items style2">
              <div className="ratting mb-3">
                <i className="fa-solid fa-star p3-clr fs-six" />
                <i className="fa-solid fa-star p3-clr fs-six" />
                <i className="fa-solid fa-star p3-clr fs-six" />
                <i className="fa-solid fa-star p3-clr fs-six" />
                <i className="fa-solid fa-star p3-clr fs-six" />
              </div>
              <p className="fs-five pra mt-xxl-4 mt-4 mb-4">
                "With a family history of diabetes, WYSE HEALTH’s Chronic Disease Prevention panel has given me peace of mind. I’ve made lifestyle changes that have improved my health dramatically."
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-xxl-4 gap-xl-3 gap-2">
                  <img
                    src="assets/img/testimonial/testimonial-john.png"
                    alt="img"
                    className="rounded-circle"
                  />
                  <div className="cont">
                    <h4 className="black">Michael K</h4>
                    <span className="fs-seven pra"> Entrepreneur</span>
                  </div>
                </div>
                <img
                  src="assets/img/testimonial/quote-icons.png"
                  alt="img"
                  className="quote"
                />
              </div>
            </div>
          </div>
          <div className="swiper-slide">
          <div className="testimonial-items style2">
            <div className="ratting mb-3">
              <i className="fa-solid fa-star p3-clr fs-six" />
              <i className="fa-solid fa-star p3-clr fs-six" />
              <i className="fa-solid fa-star p3-clr fs-six" />
              <i className="fa-solid fa-star p3-clr fs-six" />
              <i className="fa-solid fa-star p3-clr fs-six" />
            </div>
            <p className="fs-five pra mt-xxl-4 mt-4 mb-4">
              "The Beauty & Wellness insights have revolutionized my skincare routine. I now understand how to care for my skin based on my unique DNA, and the results are amazing."
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-xxl-4 gap-xl-3 gap-2">
                <img
                  src="assets/img/testimonial/testimonial-john.png"
                  alt="img"
                  className="rounded-circle"
                />
                <div className="cont">
                  <h4 className="black"> Jessica L.</h4>
                  <span className="fs-seven pra">Skincare Enthusiast</span>
                </div>
              </div>
              <img
                src="assets/img/testimonial/quote-icons.png"
                alt="img"
                className="quote"
              />
            </div>
          </div>
        </div>
            {/* Add more testimonials as needed */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</>

     <Footer/>
    </div>
  )
}

export default Home
